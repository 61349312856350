import Vue from 'vue';

import '~/plugins'
import '~/components'

import App from './App.vue'
import store from '~/store'
import router from '~/router'
import i18n from '~/plugins/i18n'

import '@mdi/font/css/materialdesignicons.css'

import vuetify from '~/plugins/vuetify'
import '~/plugins/vuejs-modal'

Vue.config.productionTip = false

import '~/directives/LanguageDirective'
import '~/plugins/system_functions'
import '~/plugins/axios'
import '~/plugins/keycloak';
import UpdateToken from '~/plugins/keycloakUpdateToken'


Vue.$keycloak
  .init({
    onLoad: 'login-required',
  })
  .then((authenticated) => {
    new Vue({
      i18n,
      store,
      router,
      vuetify,
      ...App
    })

    window.onfocus = () => {
      UpdateToken();
    };
  });


