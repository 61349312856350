//Here we set very specific functions for the program to use
export function getShortUrl ( uri ) { 
    if(!uri) return ""
    let str = uri
    if (str[0] === '<' && str[str.length - 1] === '>')
        str = str.slice(1, -1)

    if (str.includes('https://') || str.includes('http://')) {

        if (str.includes('https://'))
            str = str.slice(str.indexOf('https://') + 'https://'.length)
        else if (str.includes('http://'))
            str = str.slice(str.indexOf('http://') + 'http://')

        str = str.slice(str.indexOf('/'))
    }

    return str
} 

export function getDocumentShortUrl(uri) {
    let str = uri

    if (str[0] === '<' && str[str.length - 1] === '>')
        str = str.slice(1, -1)

    let array = str.split('/')

    return '/' + array[array.length - 2] + '/' + array[array.length - 1]
} 

export function getPropertyPredicateLabel(predicate, list){
    let lang_buffer = undefined
    let label_buffer = undefined

    if (Object.keys(list).includes(predicate))
        for (let label of list[predicate].label) {
            lang_buffer = label.substring(label.length - 2, label.length)
            label_buffer = label.substring(1, label.lastIndexOf('@') - 1)

            if (lang_buffer === process.env.MIX_ANGELUS_DEFAULT_LANG)
                break;
        }

    return label_buffer || predicate
}

export function processUriAsID(iri_string) {
    return iri_string.replace(/\.|\:|\;|\//gi, "_").replace(/^[^a-z]+|[^\w:.-]+/gi, "");
}

export function langLiteralGetLang(literal){
    return literal.substring( literal.lastIndexOf('@') + 1, literal.length )
}

export function langLiteralParse(literal){
    return literal.substring(1, literal.lastIndexOf('@') - 1)
}

export function uriToLink(uri) {
    return uri.substring(1, uri.lastIndexOf('>'))
}